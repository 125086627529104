import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import EmptyButtonImg from "../images/empty-button.png";
import GridButtonImg from "../images/grid-button.png";

const CollectionContainer = styled.main`
  max-width: 1600px;
  width: calc(100% - 300px);
  margin: 40px 150px 100px 150px;

  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    margin: 150px auto 100px auto;
  }

  @media (max-width: 500px) {
    margin: 120px auto 80px auto;
  }

  @media (min-width: 1600px) {
    margin: 40px auto 100px auto;
  }
`;

const CollectionTopbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 100px 0;
  box-sizing: border-box;
  padding-top: 5px;

  @media (max-width: 800px) {
    margin: 0 0 40px 0;
  }
`;

const CollectionTitle = styled.h1`
  font-size: 18px;
  color: black;
  margin: 0;
  width: fit-content;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const CollectionButtonWrapper = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const CollectionButton = styled.img`
  width: 24px;
  cursor: pointer;
  margin-left: 15px;
  transition: opacity 0.05s ease;

  &:hover {
    opacity: 0.4;
  }
`;

const FullButton = styled(CollectionButton)`
  opacity: ${(props) => (props.layout === "full" ? "0.4" : "1")};
`;

const GridButton = styled(CollectionButton)`
  opacity: ${(props) => (props.layout === "grid" ? "0.4" : "1")};
`;

const CollectionGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.layout === "grid" ? "1fr 1fr" : "1fr"};
  grid-gap: 100px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 60px;
  }
`;

const ProductCard = styled(Link)`
  display: grid;
  grid-template-columns: 45% 1fr;
  text-decoration: none;

  h4,
  p {
    transition: color 0.1s ease;
  }

  &:hover {
    h4 {
      color: #0000ff;
    }

    p {
      color: #0000ff;
    }
  }
`;

const ProductCardImg = styled(Img)`
  width: 100%;
  align-self: end;
`;

const ProductCardRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) =>
    props.secondaryImages ? "space-between" : "center"};
`;

const ProductCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ProductCardTitle = styled.h4`
  font-size: ${(props) => (props.layout === "full" ? "16px" : "14px")};
  color: black;
  margin: 0 0 10px 0;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const ProductCardPrice = styled.p`
  font-size: 14px;
  color: black;
  margin: 0;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const ProductCardSecondaryImages = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 40px;

  @media (max-width: 500px) {
    margin-top: 30px;
  }
`;

const ProductCardSecondaryImage = styled(Img)`
  width: 100%;
  align-self: center;
`;

const EmptyContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: black;
  margin: 0;
  text-decoration: none;

  @media (max-width: 1000px) {
    height: calc(var(--vh, 1vh) * 100);
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const EmptyLink = styled(Link)`
  font-size: 16px;
  color: #0000ff;
  margin: 10px 0 0 0;
  text-decoration: none;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const Collection = ({ pageContext }) => {
  const { collection } = pageContext;

  const [layout, setLayout] = useState("grid");

  const setToGrid = () => {
    setLayout("grid");
  };

  const setToFull = () => {
    setLayout("full");
  };

  return (
    <CollectionContainer>
      <CollectionTopbar>
        <CollectionTitle>Shop {collection.title}</CollectionTitle>
        {collection.products.length !== 0 ? (
          <CollectionButtonWrapper>
            <GridButton
              onClick={setToGrid}
              src={GridButtonImg}
              layout={layout}
            />
            <FullButton
              onClick={setToFull}
              src={EmptyButtonImg}
              layout={layout}
            />
          </CollectionButtonWrapper>
        ) : (
          <></>
        )}
      </CollectionTopbar>
      {collection.products.length !== 0 ? (
        <CollectionGrid layout={layout}>
          {collection.products.map((product) => (
            <ProductCard
              to={`/product/${product.handle}`}
              key={product.shopifyId}
            >
              <ProductCardImg
                fluid={product.images[0].localFile.childImageSharp.fluid}
                alt={product.handle}
              />
              <ProductCardRight secondaryImages={product.images.length > 1}>
                <ProductCardTextWrapper>
                  <ProductCardTitle layout={layout}>
                    {product.title}
                  </ProductCardTitle>
                  <ProductCardPrice>
                    {product.availableForSale
                      ? "$" + product.priceRange.minVariantPrice.amount
                      : "SOLD OUT"}
                  </ProductCardPrice>
                </ProductCardTextWrapper>
                {product.images.length > 1 ? (
                  <ProductCardSecondaryImages>
                    {product.images.slice(1, 3).map((image) => {
                      return (
                        <ProductCardSecondaryImage
                          fluid={image.localFile.childImageSharp.fluid}
                          alt={product.handle}
                        />
                      );
                    })}
                  </ProductCardSecondaryImages>
                ) : (
                  <></>
                )}
              </ProductCardRight>
            </ProductCard>
          ))}
        </CollectionGrid>
      ) : (
        <EmptyContainer>
          COMING SOON <br></br>
          <EmptyLink to="/collection/all">SHOP ALL</EmptyLink>
        </EmptyContainer>
      )}
    </CollectionContainer>
  );
};

export default Collection;
